import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { useShowTaskArea } from "src/api/tms-scheduling/taskArea";
import { LoadingBox } from "src/components";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { TaskAreaForm } from "src/forms/taskArea/TaskAreaForm";
import {
    Type_event_idsTaskArea,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskAreaContextualDrawer = {
    taskId: number;
    areaId: number;
    readonly?: boolean;
};

type Type_ids = { taskId: number; areaId: number };

export const TaskAreaContextualDrawer = ({
    areaId,
    taskId,
}: Type_Props_TaskAreaContextualDrawer) => {
    const [ids, setIds] = useState<Type_ids>({
        taskId: taskId,
        areaId: areaId,
    });

    useChannel({
        eventHandler: (data: Type_event_message): void => {
            if (data.event == "updateIdsTaskArea") {
                setIds({
                    taskId: (data?.data as Type_event_idsTaskArea)
                        .taskId as number,
                    areaId: (data?.data as Type_event_idsTaskArea)
                        .areaId as number,
                });
            }
        },
    });

    const { isFetching, data } = useShowTaskArea(
        ids.taskId,
        ids.areaId,
        MEDIUM_STALE_TIME,
    );

    return (
        <Styled_PaperContextualLayout
            id="taskArea"
            data-testid="TaskAreaContextualDrawer"
        >
            {isFetching ? (
                <Box paddingTop={8}>
                    <LoadingBox />
                </Box>
            ) : (
                data && <TaskAreaForm data={data} />
            )}
        </Styled_PaperContextualLayout>
    );
};
