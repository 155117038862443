import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { ProgressHistoryKeys, TaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskArea,
    formatterIndexTaskAreas,
    formatterProgressTaskArea,
    formatterShowTaskArea,
} from "src/api/tms-scheduling/taskArea/formatters";
import {
    createTaskArea,
    indexTaskAreas,
    setProgressTaskArea,
    showTaskArea,
} from "src/api/tms-scheduling/taskArea/services";
import {
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreas = (areaId: number, taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TaskAreaKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: () =>
            indexTaskAreas({
                ...requestConfig,
                areaId: areaId,
                taskId: taskId,
            }),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexTaskAreas(
                data?.data?.data as Type_sch_index_taskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowTaskArea = (
    taskId: number,
    areaId: number,
    staleTime = 0,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskAreaKeys.SHOW, { ...requestConfig, taskId, areaId }],
        queryFn: ({ signal }) =>
            showTaskArea(taskId, areaId, requestConfig, signal),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterShowTaskArea(
                data?.data?.data as Type_sch_show_taskArea,
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        staleTime,
    });
};

export const mutationCreateTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (taskArea: Type_post_taskArea) =>
            createTaskArea(formatterCreateTaskArea(taskArea), {
                ...requestConfig,
                areaId: taskArea.areaId,
                taskId: taskArea.taskId,
                taskAreaId: taskArea.id,
            }),
        onSuccess: (data, variables): void => {
            if (data && data?.success) {
                queryClient.setQueryData(
                    [
                        TaskAreaKeys.SHOW,
                        {
                            ...requestConfig,
                            areaId: variables.areaId,
                            taskId: variables.taskId,
                        },
                    ],
                    (oldData) => {
                        if (!oldData) return data;
                        // Must have the same props as the 'success' function of the response useShowTask
                        return {
                            ...oldData,
                            ...data,
                        };
                    },
                );
                sendEvent("postTaskArea");
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationSetProgressTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (progressTaskArea: Type_progress_taskArea) =>
            setProgressTaskArea(formatterProgressTaskArea(progressTaskArea), {
                ...requestConfig,
                areaId: progressTaskArea.areaId,
                taskId: progressTaskArea.taskId,
            }),
        onSuccess: (data, variables): void => {
            if (data.success) {
                // const taskArea: Type_sch_index_taskArea = resp.data.data;
                queryClient.setQueryData(
                    [
                        TaskAreaKeys.SHOW,
                        {
                            ...requestConfig,
                            areaId: variables.areaId,
                            taskId: variables.taskId,
                        },
                    ],
                    (oldData) => {
                        if (!oldData) return data;
                        // Must have the same props as the 'success' function of the response useShowTask

                        return {
                            ...oldData,
                            ...data,
                        };
                    },
                );
                queryClient.invalidateQueries([
                    ProgressHistoryKeys.INDEX,
                    {
                        ...requestConfig,
                        taskId: variables.taskId,
                        areaId: variables.areaId,
                    },
                ]);
                sendEvent("progressTaskArea" + variables.origin);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//////////////////////////////////////////////
///     Utils                              ///
//////////////////////////////////////////////

/**
 * Update taskAreaShow cache
 * @param taskAreaId
 * @param requestConfig Be careful, should be exactly the same as the one used in the query, without taskAreaId
 * @param queryClient
 */
export const updateTaskAreaIdOnShowCache = (
    taskAreaId: number,
    requestConfig: Type_RequestConfig,
    queryClient: QueryClient,
) => {
    queryClient.setQueryData(
        [
            TaskAreaKeys.SHOW,
            {
                ...requestConfig,
            },
        ],

        (oldData: any) =>
            oldData
                ? {
                      ...oldData,
                      data: {
                          ...oldData.data,
                          data: {
                              ...oldData.data.data,
                              id: taskAreaId,
                          } as Type_sch_show_taskArea,
                      },
                  }
                : null,
    );
};
