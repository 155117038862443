import { Controller, useFormContext } from "react-hook-form";

import { TMC_DateRangePicker } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_Props_TMC_DateRangePicker } from "src/components/Components_Common/_MuiComponentsVariants/DateRangePicker/DateRangePicker";

type Type_Props_DateRangePicker = Type_Props_TMC_DateRangePicker & {
    name: string;
    startLabel?: string;
    endLabel?: string;
};
export const DateRangePicker = ({
    name,
    startLabel,
    endLabel,
    ...restProps
}: Type_Props_DateRangePicker) => {
    const { formState, control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, ...field } }) => (
                <TMC_DateRangePicker
                    startLabel={startLabel}
                    endLabel={endLabel}
                    {...restProps}
                    value={value || []}
                    {...field}
                    error={!!formState.errors[name]}
                    helperText={formState.errors[name]?.message as string}
                />
            )}
        />
    );
};
