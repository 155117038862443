import { Skeleton } from "@mui/material";
import * as React from "react";
import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
    Select,
    Type_Props_Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useBreakLevelList } from "src/hooks/useBreakLevelList";

type Type_Props_SelectBreakLevel = Omit<Type_Props_Select, "options"> & {
    sequenceId?: number;
    withCurrent?: boolean;
    autoSelectFirstItem?: boolean;
};
export const SelectBreakLevel = memo(function SelectBreakLevel({
    name,
    sequenceId,
    withCurrent,
    autoSelectFirstItem = false,
    ...props
}: Type_Props_SelectBreakLevel) {
    const { isFetching, breakLevelList } = useBreakLevelList({
        sequenceId,
        withCurrent,
    });

    const { setValue } = useFormContext(); // retrieve those props

    useEffect(() => {
        if (autoSelectFirstItem && breakLevelList.length > 0) {
            setValue(name, breakLevelList[0].value);
        }
    }, [breakLevelList, autoSelectFirstItem]);

    return (
        <>
            {isFetching || !breakLevelList ? (
                <Skeleton variant="rounded" height={60} />
            ) : breakLevelList.length === 0 ? (
                // No break level available
                <></>
            ) : (
                <Select name={name} options={breakLevelList} {...props} />
            )}
        </>
    );
});
