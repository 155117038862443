import Konva from "konva";
import { Rect } from "react-konva";

import { mutationCreateTaskArea } from "src/api/tms-scheduling/taskArea";
import { colorFlowSelected } from "src/components/Components_Teamoty/Flow/Flow.const";
import {
    hitStrokeWidth,
    strokeWidthPlanningTask,
    usePlanningTheme,
    widthGripPlanningTask,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Type_Props_PlanningShapeSelectedGrid,
    Type_selected,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";

export const PlanningShapeGrip = ({
    x,
    y,

    width,
    height,

    task,

    selected,
    setSelected,

    getEndDate,
}: Type_Props_PlanningShapeSelectedGrid) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();
    // -----------------------------------------------------------------------------------------------------------------
    // Déplacement du GRIP

    const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        changeCursor(e, "ew-resize");
        setSelected((prev: Type_selected | null): Type_selected | null => ({
            ...prev!,
            task: task,
        }));
    };

    const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        e.target.y(y);

        //calcul de la nouvelle date de fin de la tache
        const newEndDate = getEndDate(selected.task!, e.target.x());

        // on met à jour la tache preview en fonction de la nouvelle date de fin
        if (newEndDate && selected.task!.beginDate.pos <= newEndDate.date.pos) {
            setSelected((prev: Type_selected | null): Type_selected | null => {
                if (prev) {
                    prev.task!.endDate = newEndDate.date;
                    prev.task!.endOffset = newEndDate.offset;
                    prev.task!.duration = newEndDate.duration;
                    prev.width = e.target.x() - x;
                    return { ...prev };
                }
                return prev;
            });
        } else {
            e.target.x(x + width);
        }
    };

    const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        // on calcule la nouvelle date de fin de la tache
        const newEndDate = getEndDate(selected.task!, e.target.x());

        changeCursor(e, "progress");

        handleSubmit(newEndDate);

        setSelected(null);
    };

    // -----------------------------------------------------------------------------------------------------------------
    // envoi de la nouvelle duration de la taskArea au back
    const { mutate } = mutationCreateTaskArea();

    const handleSubmit = (newEndDate: any): void => {
        mutate({
            areaId: selected.task!.areaId!,
            taskId: selected.task!.id,
            duration: newEndDate.duration,
        });
    };

    return (
        <Rect
            x={x + width}
            y={y}
            offsetX={widthGripPlanningTask / 2}
            offsetY={-height / 4}
            width={widthGripPlanningTask}
            height={height / 2}
            fill={theme.backgroundColorPlanningTask}
            stroke={colorFlowSelected}
            strokeWidth={strokeWidthPlanningTask}
            hitStrokeWidth={hitStrokeWidth}
            draggable={true}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEnd}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, "ew-resize")
            }
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, defaultCursor)
            }
        />
    );
};
