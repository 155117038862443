import {
    Type_post_print,
    Type_printSettings,
} from "src/api/tms-print/print/types";
import {
    Type_data_view,
    Type_data_view_gantt,
    Type_data_view_planning,
    Type_default_view,
    Type_index_view,
    Type_post_view,
    Type_prj_index_view,
    Type_prj_post_view,
    Type_prj_put_view,
    Type_prj_show_view,
    Type_prj_view_data,
    Type_settings_dashboard,
    Type_settings_planning,
    Type_show_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";

export const currentViewVersion = 1;

export const formatterCreateView = (
    view: Type_default_view,
    workspaceId: number,
): Type_prj_post_view => {
    return {
        name: view.name,
        order: view.order,
        workspace_id: workspaceId,
        data: view.data,
    };
};

export const formatterIndexView = (
    view: Type_prj_index_view,
): Type_index_view => {
    return {
        ...view,
        data: formatterViewDataByVersion(view.data),
    };
};

export const formatterShowView = (view: Type_prj_show_view): Type_show_view => {
    return {
        id: view.id,
        name: view.name,
        order: view.order,
        enabled: view.enabled,
        data: formatterViewDataByVersion(view.data),
    };
};

export const formatterUpdateView = (
    view: Type_post_view,
): Type_prj_put_view => {
    return {
        name: view.name,
        order: view.order,
        enabled: view.enabled,
        data: view.data,
    };
};

export const formatterPostPrintToPrintSettings = (
    data: Type_post_print,
): Type_printSettings => {
    return {
        paperFormat: data.paperFormat,
        orientation: data.orientation,
        timelineType: data.timelineType,
        displayCaption: data.displayCaption,
        captionPosition: data.captionPosition,
        displayCaptionKeydates: data.displayCaptionKeydates,
    };
};

const formatterViewDataByVersion = (
    data: Type_prj_view_data,
): Type_data_view => {
    // TODO Mettre ici la logique de migration de version automatique pour les données de Type_prj_view_data > Type_data_view

    const result = {
        type: data.type,
        version: currentViewVersion,
        settings: {},
    };

    if (data.type === "planning") {
        result.settings = {
            print: (data.settings as Type_settings_planning)
                .print as Type_printSettings,
        };

        return result as Type_data_view_planning;
    }

    if (data.type === "gantt") {
        result.settings = {
            print: (data.settings as Type_settings_planning)
                .print as Type_printSettings,
        };

        return result as Type_data_view_gantt;
    }

    if (data.type === "dashboard") {
        result.settings = {
            ...(data.settings as Type_settings_dashboard),
        };
    }

    return result as Type_data_view;
};
