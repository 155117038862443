import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Checkbox, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationCreateCompany,
    useIndexCompanies,
} from "src/api/tms-commons/companies";
import { formatterIndexCompanies } from "src/api/tms-commons/companies/formatters";
import { searchCompany } from "src/api/tms-commons/companies/services";
import {
    Type_com_index_company,
    Type_index_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";
import {
    mutationCreateProjectCompany,
    mutationUpdateProjectCompany,
    useShowProjectCompany,
} from "src/api/tms-projects/projectCompanies";
import {
    Type_post_projectCompany,
    Type_show_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { useSelectListSubTrades } from "src/api/tms-projects/subTrades";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_CompanyForm = {
    onClose: () => void;
    action: Type_action;
    companyIdToUpdate: number | null;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    requestConfig: Type_RequestConfig;
};

const Schema_CompanyForm = Yup.lazy(() => {
    return Yup.object().shape({
        id: Yup.number().nullable(),
        company: Yup.object().required(FORM_ERR_FMT.REQUIRED),
        nationalId: Yup.string().nullable(),
        postalCode: Yup.string()
            .trim()
            .max(10, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        city: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        country: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        team: Yup.number()
            .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
            .integer(FORM_ERR_FMT.INTEGER)
            .positive(FORM_ERR_FMT.MUST_BE_POSITIF)
            .required(FORM_ERR_FMT.REQUIRED),
        subTrades: Yup.array().min(1, FORM_ERR_FMT.MIN_SELECT).optional(),
    });
});

export const ProjectCompanyForm = ({
    onClose,
    action,
    companyIdToUpdate,
    setIsLoading,
    requestConfig,
}: Type_Props_CompanyForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const [showSearch, setShowSearch] = useState<Type_index_company[]>([]);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [disableCompany, setDisableCompany] = useState<boolean>(false);
    const actionCanUpdate = action === "update";
    const isDisabled = disableCompany || actionCanUpdate;

    const { mutateAsync: mutateCreate } =
        mutationCreateProjectCompany(requestConfig) || {};

    const { mutateAsync: mutateCreateCompany } = mutationCreateCompany() || {};

    const { mutateAsync: mutateUpdate } =
        mutationUpdateProjectCompany(requestConfig) || {};

    const { data, isFetching } = useShowProjectCompany(
        companyIdToUpdate as number,
    );

    const { data: companiesList, isFetching: isFetchingCompaniesList } =
        useIndexCompanies() || {};
    const { data: subTradesList, isFetching: isFetchingTradesList } =
        useSelectListSubTrades() || {};

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault;

        setIsLoading(true);
        // TODO: isDirty... Beaucoup trop de setValues, refacto à prévoir avec formatter
        searchCompany(values.company.name, values.company.id)
            .then(async (data) => {
                if (
                    !disableCompany &&
                    !confirm &&
                    Array.isArray(data.data.data) &&
                    data.data.data.length > 0
                ) {
                    setShowSearch(
                        formatterIndexCompanies(
                            data?.data?.data as Type_com_index_company[],
                        ),
                    );
                } else {
                    try {
                        if (!disableCompany) {
                            mutateCreateCompany({
                                ...values,
                                name: values.company.name,
                            } as Type_show_company).then((data) => {
                                if (action === "create") {
                                    values.id = data?.data?.data.id;
                                    values.technicalName =
                                        data?.data?.data.technicalName;
                                    mutateCreate(
                                        values as Type_post_projectCompany,
                                    );
                                } else if (action === "update") {
                                    mutateUpdate(
                                        values as Type_show_projectCompany,
                                    );
                                }
                            });
                        } else if (action === "create") {
                            values.id = values.company.id;
                            values.technicalName = values.company.name;
                            mutateCreate(values);
                        } else if (action === "update") {
                            mutateUpdate(values);
                        }
                    } catch (e: any) {
                        console.error("Error when creating or updating ");
                    }
                    onClose();
                }
            })
            .catch((error) => console.log(error));

        setIsLoading(false);
    };

    const searchCompanyForDisable = (companySelected: {
        id: number;
        name: string;
    }) => {
        if (
            companiesList?.companies?.find(
                (elem) => elem.name === companySelected.name,
            )
        ) {
            setDisableCompany(true);
        } else {
            setDisableCompany(false);
        }
    };

    const form = useForm({
        defaultValues: {
            company: "",
            companyName: "",
            nationalId: "",
            postalCode: "",
            city: "",
            country: "",
            subTrades: [],
        },
        values: data,
        resolver: yupResolver<any>(Schema_CompanyForm),
    });

    useEffect(() => {
        const company = form.getValues("company");
        const companyName = company?.name || form.getValues("companyName");
        const companyData = companiesList?.companies?.find(
            (company) => company.name === companyName,
        );

        const isCompanyDisabled = !companyName || companyData;

        setDisableCompany(!!isCompanyDisabled);

        if (companyData) {
            form.setValue("companyName", companyData.name);
            form.setValue("nationalId", companyData.nationalId || "");
            form.setValue("postalCode", companyData.postalCode);
            form.setValue("city", companyData.city);
            form.setValue("country", companyData.country);
        }
    }, [companiesList, form.watch("company")]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"projectCompanies"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <AutocompleteFreeSoloDialog
                            isFetching={isFetchingCompaniesList}
                            multiple={false}
                            options={companiesList?.companies || []}
                            name="company"
                            freeSolo={true}
                            onAddNewValue={(value) => {
                                form.setValue("company", value);
                                searchCompanyForDisable(value);
                            }}
                            renderInputProps={{ variant: "outlined" }}
                            disabled={actionCanUpdate}
                        />
                        <TextField
                            name="nationalId"
                            label={fmt(`NationalId`)}
                            disabled={isDisabled}
                        />
                        <TextField
                            name="postalCode"
                            label={fmt(`ZipCode`)}
                            disabled={isDisabled}
                        />
                        <TextField
                            name="city"
                            label={fmt(`CityLabel`)}
                            disabled={isDisabled}
                        />
                        <TextField
                            name="country"
                            label={fmt(`CountryLabel`)}
                            disabled={isDisabled}
                        />
                        <TextField
                            name="team"
                            label={fmt(`Team`)}
                            type="number"
                        />
                        <Typography>{fmt("Trades")}</Typography>
                        <Autocomplete
                            isFetching={isFetchingTradesList}
                            multiple={true}
                            options={subTradesList || []}
                            name="subTrades"
                            renderInputProps={{ variant: "outlined" }}
                        ></Autocomplete>
                        {showSearch.length > 0 ? (
                            <>
                                <Alert severity="error">
                                    {fmt("CompaniesSimilar")}
                                    <ul>
                                        {showSearch.map((elem, index) => (
                                            <li key={index}>
                                                {"• " +
                                                    elem.name +
                                                    ", " +
                                                    elem.city +
                                                    ", " +
                                                    elem.country}
                                            </li>
                                        ))}
                                    </ul>
                                </Alert>
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Checkbox
                                        onClick={() => setConfirm(!confirm)}
                                        checked={confirm}
                                    />
                                    <Typography mt={2}>
                                        {fmt("ConfirmCompany")}
                                    </Typography>
                                </Stack>
                            </>
                        ) : (
                            ""
                        )}
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
