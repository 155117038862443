import { Stack, Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useQueryClient } from "react-query";
import { QueryClient } from "react-query/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Type_RequestConfig } from "src/api/fetch";
import { SubProjectKeys } from "src/api/tms-projects/keys";
import { useCurrentWorkspaceSubProject } from "src/api/tms-projects/subProjects";
import { formatterCreateView } from "src/api/tms-projects/workspacesAndViews/views/formatters";
import { createView } from "src/api/tms-projects/workspacesAndViews/views/services";
import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { formatterCreateWorkspace } from "src/api/tms-projects/workspacesAndViews/workspaces/formatters";
import {
    createWorkspace,
    showWorkspace,
} from "src/api/tms-projects/workspacesAndViews/workspaces/services";
import { Type_show_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { LoadingBox } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    APP_BAR_HEIGHT,
    TopBarLeft_IconButton,
} from "src/components/Components_Teamoty/TopBar/stylesheet";
import { useProject } from "src/contexts/project";
import { WorkspaceSelection } from "src/dialogs/WorkspaceSelection";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { ViewsTabs } from "src/layouts/Layout_Workspace/Views/ViewsTabs";
import { Page_Views } from "src/pages";

import { getDefaultSubprojectConfiguration } from "./configurations/workspacesSubproject";

type Type_Props_ActiveAndCreateWorkspace = {
    queryClient: QueryClient;
    type: string;
    requestConfig: Type_RequestConfig;
    setProgressValue: Dispatch<SetStateAction<number>>;
    setCurrentView: Dispatch<SetStateAction<Type_index_view | undefined>>;
};

const activeAndCreateWorkspace = async ({
    queryClient,
    type,
    requestConfig,
    setProgressValue,
    setCurrentView,
}: Type_Props_ActiveAndCreateWorkspace) => {
    try {
        // change current workspace
        await showWorkspace(type, requestConfig);
    } catch (e) {
        // Not find workspace => Create workspace and views
        try {
            const workspaceConfig = getDefaultSubprojectConfiguration();
            const newWorkspace = workspaceConfig.get(type);

            if (newWorkspace) {
                // Find workspace for type
                // Create workspaces
                const createdWorkspace = await createWorkspace(
                    formatterCreateWorkspace(newWorkspace),
                    requestConfig,
                );

                // Create views for workspace
                const workspaceViews = newWorkspace.views;
                const pas = 100 / workspaceViews.length;
                let progress = 0;
                for (const view of workspaceViews) {
                    setProgressValue((progress += pas));
                    await createView(
                        formatterCreateView(
                            view,
                            createdWorkspace.data?.data?.id,
                        ),
                        requestConfig,
                    );
                }
            }

            // Change current workspace
            await showWorkspace(type, requestConfig);
        } catch (error) {
            console.error("Error creating workspaces and views:", error);
        }
    }

    // Close progress bar
    setProgressValue(100);

    // Forced default view
    setCurrentView(undefined);

    // force reload view
    await queryClient.invalidateQueries({
        queryKey: [
            SubProjectKeys.CURRENT_WORKSPACE,
            {
                projectId: requestConfig.projectId,
                subProjectId: requestConfig.subProjectId,
            },
        ],
    });
};

export const Layout_Workspace = () => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Workspace.Menu",
    );
    const { view } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const topBarLeftContent = document.getElementById("topBarLeftContent");
    const workspacesMenuContent = document.getElementById(
        "workspacesMenuContent",
    );
    //////////////////////////////////
    ///     States
    //////////////////////////////////

    const [workspace, setWorkspace] = useState<Type_show_workspace | null>(
        null,
    );
    const [currentView, setCurrentView] = useState<Type_index_view | undefined>(
        undefined,
    );
    const [open, setOpen] = useState<boolean>(false);

    const [progressValue, setProgressValue] = useState<number>(0);
    const [withProgress, setWithProgress] = useState<boolean>(false);

    //////////////////////////////////
    ///     Queries
    //////////////////////////////////
    const { isFetching, data } = useCurrentWorkspaceSubProject(
        requestConfig.projectId as number,
        true,
        requestConfig.subProjectId as number,
    );

    //////////////////////////////////
    ///     Effects
    //////////////////////////////////

    // Fetch and set workspace data (view)
    useEffect(() => {
        if (!isFetching && data) {
            if (data.currentWorkspace === null) {
                setWorkspace(null);
                handleOpen();
            } else {
                const newCurrentView = data.currentWorkspace.views.find(
                    (viewItem) => viewItem.id == parseInt(view || "0"),
                );
                !currentView &&
                    setCurrentView(
                        newCurrentView
                            ? newCurrentView
                            : data.currentWorkspace.views[0],
                    );
                setWorkspace(data.currentWorkspace);
            }
        }
    }, [isFetching, data, data?.currentWorkspace]);

    // Update URL when currentView changes
    useEffect(() => {
        if (currentView) {
            const workspaceUrl = "/workspace";
            const tabLocation = pathname.split(workspaceUrl)[0];
            const newUrl = `${tabLocation}${workspaceUrl}/${currentView.id}`;
            if (newUrl !== pathname) {
                navigate(newUrl, { replace: true }); // Replace the current history entry to avoid redundant navigation
            }
        }
    }, [currentView, pathname, navigate]);

    //////////////////////////////////
    ///     Handlers
    //////////////////////////////////
    const handleChangeTab = (newValue: number) => {
        if (workspace) {
            const newView = workspace.views.find(
                (view) => view.id === newValue,
            );
            if (newView && newView.id !== currentView?.id) {
                setCurrentView(newView);
            }
        }
    };

    const addNewView = async (newView: Type_index_view) => {
        setCurrentView(newView);
        await queryClient.invalidateQueries({
            queryKey: [
                SubProjectKeys.CURRENT_WORKSPACE,
                {
                    projectId: requestConfig.projectId,
                    subProjectId: requestConfig.subProjectId,
                },
            ],
        });

        const workspaceUrl = "/workspace";
        const tabLocation = pathname.split(workspaceUrl)[0];
        navigate(`${tabLocation}${workspaceUrl}/${newView.id}`);
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleClick = async (type: string) => {
        setWithProgress(true);
        await activeAndCreateWorkspace({
            queryClient,
            type,
            requestConfig,
            setProgressValue,
            setCurrentView,
        });
        setWithProgress(false);

        handleClose();
    };

    // reinitialisation du progress
    if (progressValue && !withProgress) {
        setProgressValue(0);
    }

    return (
        <>
            {workspacesMenuContent &&
                createPortal(
                    <Stack direction="row" height={APP_BAR_HEIGHT}>
                        <Tooltip title={fmt("Tooltip")}>
                            <TopBarLeft_IconButton
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleOpen}
                                data-testid="WorkspacesMenu-open-btn"
                                aria-controls={open ? "views-menu" : undefined}
                                aria-expanded={open}
                            >
                                <Icon
                                    variant="kit"
                                    icon="light-table-layout-sparkle"
                                    sx={{ width: "24px" }}
                                />
                            </TopBarLeft_IconButton>
                        </Tooltip>
                    </Stack>,
                    workspacesMenuContent,
                )}
            {topBarLeftContent && !isFetching
                ? workspace?.views?.length &&
                  createPortal(
                      <ViewsTabs
                          currentView={currentView}
                          workspace={workspace}
                          handleChangeTab={handleChangeTab}
                          addNewView={addNewView}
                      />,
                      topBarLeftContent,
                  )
                : null}
            {!isFetching ? <Page_Views view={currentView} /> : <LoadingBox />}
            <WorkspaceSelection
                isLoading={isFetching}
                handleClose={handleClose}
                selectedType={workspace?.type}
                handleClick={handleClick}
                open={open}
                withConfirmation={workspace?.type !== undefined}
                progressValue={progressValue}
                withProgress={withProgress}
            />
        </>
    );
};
