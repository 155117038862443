import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TaskAreasPath, Url } from "src/api/paths";
import {
    Type_sch_post_taskArea,
    Type_sch_progress_taskArea,
} from "src/api/tms-scheduling/taskArea/types";

export const indexTaskAreas = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${TaskAreasPath.TASK_AREAS}`, requestConfig);
};

export const showTaskArea = (
    taskId: number,
    areaId: number,
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREAS}/${taskId}/${areaId}`,
        { ...requestConfig, signal },
    );
};

export const createTaskArea = (
    data: Type_sch_post_taskArea,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREAS}`,
        requestConfig,
    );
};

export const setProgressTaskArea = (
    data: Type_sch_progress_taskArea,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREAS_PROGRESS}`,
        requestConfig,
    );
};
