import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import {
    Type_planning,
    Type_planningArea,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { updateTaktPlanning } from "@cimba-digital-construction-solution/utils/dist/decoder/updateTaktPlanning";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import React, {
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { Group, Layer, Rect } from "react-konva";

import { getHeaders } from "src/api/fetch";
import { PlanningPath, Url } from "src/api/paths";
import { DropDownMenuRightClick } from "src/components/Components_Common/dropdownMenuRightClick/DropDownMenuRightClick";
import { DropDownMenuRightClickEditKeyDate } from "src/components/Components_Common/dropdownMenuRightClick/DropDownMenuRightClickEditKeyDate";
import { Type_selected } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { PlanningTasks } from "src/components/Components_Teamoty/Planning/Task/PlanningTasks";
import { Enum_typeChart } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { Type_State_IsLoading } from "src/components/Components_Teamoty/views/ViewPlanning/ViewPlanning";
import { usePlanningContext } from "src/contexts/planning";
import { useProject } from "src/contexts/project";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

import { Type_Props_PlanningAreasOffset } from "./Areas/PlanningArea.type";
import { PlanningAreas } from "./Areas/PlanningAreas";
import { PlanningAreasTitle } from "./Areas/PlanningAreasTitle";
import { PlanningColsData } from "./ColData/PlanningColsData";
import {
    initialPlanningMonthsDays,
    Type_Props_PlanningMonthsDays,
} from "./Dates/PlanningDate.type";
import { PlanningDates } from "./Dates/PlanningDates";
import { PlanningDialogsRightClick } from "./Dialogs";
import { Type_PlanningDrawer } from "./Drawer/PlanningDrawer.type";
import { PlanningGrid } from "./Grid/PlanningGrid";
import {
    heightPlanningTitle,
    minPlanningDrawerHeight,
    planningEvents,
    sizePlanningZoom,
} from "./Planning.const";
import {
    initialPlanningAreas,
    initialPlanningColsData,
    initialPlanningColsDataSize,
    initialPlanningWorkforceCompaniesTab,
    initialPlanningWorkforceCurve,
    initialPlanningWorkforceSubTradesTab,
} from "./Planning.init";
import {
    Type_Map_PlanningColsDataSize,
    Type_PlanningPositionsStorage,
    Type_Props_Planning,
    Type_State_PlanningDrawerSizes,
    Type_state_PlanningViewFilters,
} from "./Planning.type";
import {
    initialPlanningBounds,
    initialPlanningScroll,
    Type_State_PlanningScroll,
} from "./Scrolls/PlanningScroll.type";
import { PlanningScrolls } from "./Scrolls/PlanningScrolls";
import { PlanningStage } from "./Stage/PlanningStage";
import { updateAreasOffset } from "./tools/updateAreasOffset";
import { updateBounds } from "./tools/updateBounds";
import { updateDataPlanning } from "./tools/updateDataPlanning";
// eslint-disable-next-line import/order
import { updateDatesMonthsDays } from "./tools/updateDatesMonthsDays";
//eslint-disable-next-line
import WorkerPlanning from "./Workers/planning.worker?worker";
import { PlanningWorkforceCompaniesTab } from "./Workforce/PlanningWorkforceCompaniesTab";
import { PlanningWorkforceCurve } from "./Workforce/PlanningWorkforceCurve";
import { PlanningWorkforceSubTradesTab } from "./Workforce/PlanningWorkforceSubTradesTab";
import {
    initialPlanningZoomX,
    initialPlanningZoomY,
    Type_PlanningZoom,
} from "./Zooms/PlanningZoom.type";

const Styled_Planning = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",

    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
}));

export const Planning = ({
    bounds,
    isLoading,
    setIsLoading,
}: Type_Props_Planning) => {
    const { requestConfig } = useProject();
    const {
        dataPlanning,
        setDataPlanning,
        viewFilters,
        setViewFilters,
        dataFilters,
    } = usePlanningContext();

    const workerRef = useRef<Worker | null>(null);
    const refStage: RefObject<Konva.Stage> = useRef<Konva.Stage | null>(null);

    const [currentProject, setCurrentProject] = useState({
        projectId: 0,
        subProjectId: 0,
    });

    // -----------------------------------------------------------------------------------------------------------------
    // cursor
    const [cursorHighlight, setCursorHighlight] = useState<Vector2d>({
        x: -1,
        y: -1,
    });

    // -----------------------------------------------------------------------------------------------------------------
    // Worker

    const generatePlanning = useCallback(() => {
        const worker: Worker = new WorkerPlanning();
        workerRef.current = worker;

        worker.addEventListener("message", (event) => {
            const data = event.data;

            switch (data.cmd) {
                case "end":
                    setDataPlanning(data.result);
                    setIsLoading((prev: Type_State_IsLoading) => {
                        prev.loading = false;
                        prev.withPlanning =
                            prev.withPlanning ||
                            !!(
                                data.result.areas.length &&
                                data.result.dates.size
                            );
                        return { ...prev };
                    });
                    setViewFilters((prev: Type_state_PlanningViewFilters) => {
                        return {
                            ...prev,
                            ...getLocalStorageItem(
                                `planning_settings_${requestConfig.projectId}_${requestConfig.subProjectId}`,
                            ),
                        };
                    });
                    break;

                case "error":
                    console.error("Erreur :", data.error);
                    setIsLoading({ loading: false, withPlanning: false });
                    break;

                default:
                    break;
            }
        });

        worker.postMessage({
            cmd: "start",
            type: "TaktPlanning",
            url: `${Url.PLANNING}${PlanningPath.MAKE}`,
            filters: dataFilters,
            headers: getHeaders(requestConfig),
        });

        return () => {
            worker.terminate();
        };
    }, [requestConfig.projectId, requestConfig.subProjectId, dataFilters]);

    // relance la generation du planning à chaque event
    useChannel({
        eventHandler: (data: Type_event_message): void => {
            if (planningEvents.includes(data.event)) {
                console.warn("generatePlanning", data.event);
                generatePlanning();
            }
            if (data.event == "updatePlanningSettings") {
                const settings = data.data as object;
                setViewFilters((prev) => {
                    return {
                        ...prev,
                        ...settings,
                    };
                });
            }
        },
    });

    if (
        requestConfig.projectId &&
        requestConfig.subProjectId &&
        (currentProject.projectId != requestConfig.projectId ||
            currentProject.subProjectId != requestConfig.subProjectId)
    ) {
        setCurrentProject({
            projectId: requestConfig.projectId,
            subProjectId: requestConfig.subProjectId,
        });

        generatePlanning();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Keyboard

    const handleKeyDown = useCallback((e: KeyboardEvent): void => {
        console.log("handleKeyDown", e.key);
        e.preventDefault();
    }, []);

    useEffect(() => {
        if (refStage?.current) {
            const stage: Konva.Stage = refStage.current;
            const container: HTMLDivElement = stage.container();

            container.tabIndex = 1;
            container.focus();

            container.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (refStage?.current) {
                const stage: Konva.Stage = refStage.current;
                const container = stage.container();

                container.tabIndex = 1;
                container.focus();

                container.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [handleKeyDown]);

    // -----------------------------------------------------------------------------------------------------------------

    const getLocalStorage = (): Type_PlanningPositionsStorage => {
        return (
            getLocalStorageItem(
                `planning_positions_${requestConfig.projectId}_${requestConfig.subProjectId}`,
            ) || {
                scrollX: initialPlanningScroll.pos,
                scrollY: initialPlanningScroll.pos,
                zoomX: initialPlanningZoomX.pos,
                zoomY: initialPlanningZoomY.pos,
            }
        );
    };

    // -----------------------------------------------------------------------------------------------------------------

    const [boundsPlanning, setBoundsPlanning] = useState<Type_PlanningDrawer>(
        initialPlanningBounds,
    );

    const [scrollX, setScrollX] = useState<Type_State_PlanningScroll>({
        ...initialPlanningScroll,
        pos: getLocalStorage().scrollX || initialPlanningScroll.pos,
    });
    const [scrollY, setScrollY] = useState<Type_State_PlanningScroll>({
        ...initialPlanningScroll,
        pos: getLocalStorage().scrollY || initialPlanningScroll.pos,
    });

    const [zoomX, setZoomX] = useState<Type_PlanningZoom>({
        ...initialPlanningZoomX,
        pos: getLocalStorage().zoomX || initialPlanningZoomX.pos,
    });
    const [zoomY, setZoomY] = useState<Type_PlanningZoom>({
        ...initialPlanningZoomY,
        pos: getLocalStorage().zoomY || initialPlanningZoomY.pos,
    });

    const [workforceCurve, setWorkforceCurve] = useState<Type_PlanningDrawer>(
        initialPlanningWorkforceCurve,
    );

    const [workforceSubTradeTab, setWorkforceSubTradesTab] =
        useState<Type_PlanningDrawer>(initialPlanningWorkforceSubTradesTab);

    const [workforceCompaniesTab, setWorkforceCompaniesTab] =
        useState<Type_PlanningDrawer>(initialPlanningWorkforceCompaniesTab);

    const [areas, setAreas] =
        useState<Type_PlanningDrawer>(initialPlanningAreas);

    const [colsData, setColsData] = useState<Type_PlanningDrawer>(
        initialPlanningColsData,
    );

    const [selected, setSelected] = useState<Type_selected | null>(null);

    // -----------------------------------------------------------------------------------------------------------------

    const setLocalStorage = (values: Type_PlanningPositionsStorage): void => {
        setLocalStorageItem(
            `planning_positions_${requestConfig.projectId}_${requestConfig.subProjectId}`,
            {
                scrollX: scrollX.pos,
                scrollY: scrollY.pos,
                zoomX: zoomX.pos,
                zoomY: zoomY.pos,
                ...values,
            },
        );
    };

    // -----------------------------------------------------------------------------------------------------------------

    const [colsDataSize, setColsDataSize] =
        useState<Type_Map_PlanningColsDataSize>(initialPlanningColsDataSize);

    // -----------------------------------------------------------------------------------------------------------------

    const changeScrollX = useCallback((pos: number): void => {
        setScrollX(
            (prev: Type_State_PlanningScroll): Type_State_PlanningScroll => {
                prev.pos = pos;
                setLocalStorage({ scrollX: pos });
                return { ...prev };
            },
        );
    }, []);

    const changeScrollY = useCallback((pos: number): void => {
        setScrollY(
            (prev: Type_State_PlanningScroll): Type_State_PlanningScroll => {
                prev.pos = pos;
                setLocalStorage({ scrollY: pos });
                return { ...prev };
            },
        );
    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    const changeZoomX = useCallback((pos: number, delta?: number): void => {
        setZoomX((prevZoom: Type_PlanningZoom): Type_PlanningZoom => {
            prevZoom.pos = delta !== undefined ? prevZoom.pos + delta : pos;
            prevZoom.pos = Math.min(
                prevZoom.max,
                Math.max(prevZoom.min, prevZoom.pos),
            );

            setScrollX(
                (
                    prev: Type_State_PlanningScroll,
                ): Type_State_PlanningScroll => {
                    prev.page = Math.min(
                        prev.size,
                        prev.widthPage / prevZoom.pos,
                    );
                    prev.pos = Math.min(
                        Math.max(0, prev.pos),
                        prev.size - prev.page,
                    );
                    return { ...prev };
                },
            );

            setLocalStorage({ zoomX: prevZoom.pos });

            return { ...prevZoom };
        });
    }, []);

    const changeZoomY = useCallback((pos: number, delta?: number): void => {
        setZoomY((prevZoom: Type_PlanningZoom): Type_PlanningZoom => {
            prevZoom.pos = delta !== undefined ? prevZoom.pos + delta : pos;
            prevZoom.pos = Math.min(
                prevZoom.max,
                Math.max(prevZoom.min, prevZoom.pos),
            );

            setScrollY(
                (
                    prev: Type_State_PlanningScroll,
                ): Type_State_PlanningScroll => {
                    prev.page = Math.min(
                        prev.size,
                        prev.widthPage / prevZoom.pos,
                    );
                    prev.pos = Math.min(
                        Math.max(0, prev.pos),
                        prev.size - prev.page,
                    );
                    return { ...prev };
                },
            );

            setLocalStorage({ zoomY: prevZoom.pos });

            return { ...prevZoom };
        });
    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    const [drawerSizes, changeDrawerSizes] =
        useState<Type_State_PlanningDrawerSizes>({
            heightWorkforceCurve: minPlanningDrawerHeight,
            heightWorkforceSubTradesTab: minPlanningDrawerHeight,
            heightWorkforceCompaniesTab: minPlanningDrawerHeight,
            widthAreas: 200,
            widthColsData: 200,
        });

    const minPlanningHeight = Math.trunc((sizePlanningZoom * 5) / 3);
    const maxPlanningDrawerHeight = Math.trunc(
        Math.max(bounds.height - minPlanningHeight, minPlanningHeight),
    );

    // -----------------------------------------------------------------------------------------------------------------

    const resizeWorkforceCurve = useCallback(
        (size: number) => {
            changeDrawerSizes(
                (
                    prev: Type_State_PlanningDrawerSizes,
                ): Type_State_PlanningDrawerSizes => ({
                    ...prev,
                    heightWorkforceCurve: Math.max(
                        minPlanningDrawerHeight,
                        Math.min(bounds.height - size, maxPlanningDrawerHeight),
                    ),
                }),
            );
        },
        [maxPlanningDrawerHeight],
    );

    const resizeWorkforceSubTradeTab = useCallback(
        (size: number) => {
            changeDrawerSizes(
                (
                    prev: Type_State_PlanningDrawerSizes,
                ): Type_State_PlanningDrawerSizes => ({
                    ...prev,
                    heightWorkforceSubTradesTab: Math.max(
                        minPlanningDrawerHeight,
                        Math.min(bounds.height - size, maxPlanningDrawerHeight),
                    ),
                }),
            );
        },
        [maxPlanningDrawerHeight],
    );

    const resizeWorkforceCompaniesTab = useCallback(
        (size: number) => {
            changeDrawerSizes(
                (
                    prev: Type_State_PlanningDrawerSizes,
                ): Type_State_PlanningDrawerSizes => ({
                    ...prev,
                    heightWorkforceCompaniesTab: Math.max(
                        minPlanningDrawerHeight,
                        Math.min(bounds.height - size, maxPlanningDrawerHeight),
                    ),
                }),
            );
        },
        [maxPlanningDrawerHeight],
    );

    const onChangeCloseArea = useCallback(() => {
        setDataPlanning((prev) => {
            if (prev) {
                updateTaktPlanning(prev as Type_planning);
                return { ...prev };
            }
            return prev;
        });
    }, [setDataPlanning]);

    const resizeAreas = useCallback((size: number) => {
        changeDrawerSizes(
            (
                prev: Type_State_PlanningDrawerSizes,
            ): Type_State_PlanningDrawerSizes => ({
                ...prev,
                widthAreas: Math.min(300, size),
            }),
        );
    }, []);

    const resizeColsData = useCallback((size: number) => {
        changeDrawerSizes(
            (
                prev: Type_State_PlanningDrawerSizes,
            ): Type_State_PlanningDrawerSizes => ({
                ...prev,
                widthColsData: Math.min(1600, size),
            }),
        );
    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        updateBounds(
            bounds,
            viewFilters,
            drawerSizes,
            setBoundsPlanning,
            setWorkforceCurve,
            setWorkforceSubTradesTab,
            setWorkforceCompaniesTab,
            setZoomX,
            setZoomY,
            setScrollX,
            setScrollY,
            setAreas,
            setColsData,
            getLocalStorage,
        );
    }, [bounds, viewFilters, drawerSizes]);

    // -----------------------------------------------------------------------------------------------------------------
    // Change data planning

    const widthDate = zoomX.pos;
    const heightArea = zoomY.pos;

    const [datesMonthsDays, setDatesMonthsDays] =
        useState<Type_Props_PlanningMonthsDays>(initialPlanningMonthsDays);
    const [datesActif, setDatesActif] = useState<Array<planningDateClass>>([]);

    const [areasOffset, setAreasOffset] =
        useState<Type_Props_PlanningAreasOffset>({
            offset: 0,
            lines: [],
            areas: [],
        });
    const [areasActif, setAreasActif] = useState<Array<Type_planningArea>>([]);

    useEffect(() => {
        updateDataPlanning(
            dataPlanning,
            viewFilters,
            heightArea,
            setDatesActif,
            setAreasActif,
            setScrollX,
            setScrollY,
        );
        setSelected(null);
    }, [dataPlanning, viewFilters]);

    useEffect(() => {
        updateDatesMonthsDays(
            dataPlanning,
            datesActif,
            scrollX,
            widthDate,
            setCursorHighlight,
            setDatesMonthsDays,
        );
    }, [scrollX]);

    useEffect(() => {
        updateAreasOffset(
            areasActif,
            scrollY,
            heightArea,
            setCursorHighlight,
            setAreasOffset,
        );
    }, [scrollY]);

    // ----------------------------------------------------------------------------------------------------------------

    return (
        <>
            {isLoading.withPlanning && (
                <Styled_Planning data-testid="Planning-Styled_Planning">
                    <DropDownMenuRightClick name="Planning" />
                    <DropDownMenuRightClickEditKeyDate name="Planning-timeline" />
                    <PlanningStage
                        refStage={refStage}
                        bounds={bounds}
                        boundsPlanning={boundsPlanning}
                        datesActif={datesActif}
                        areasOffset={areasOffset}
                        widthDate={widthDate}
                        heightArea={heightArea}
                        scrollX={scrollX}
                        scrollY={scrollY}
                        setCursorHighlight={setCursorHighlight}
                        setScrollX={setScrollX}
                        setScrollY={setScrollY}
                        changeZoomX={changeZoomX}
                        changeZoomY={changeZoomY}
                    >
                        <Layer>
                            <Rect
                                x={0}
                                y={0}
                                width={bounds.width}
                                height={bounds.height}
                                fill={"white"}
                            />
                        </Layer>
                        <PlanningGrid
                            bounds={bounds}
                            areasOffset={areasOffset}
                            dates={datesMonthsDays}
                            datesActif={datesActif}
                            x={boundsPlanning.x}
                            y={boundsPlanning.y}
                            width={boundsPlanning.width}
                            height={boundsPlanning.height}
                            cursorHighlight={cursorHighlight}
                            widthDate={widthDate}
                            heightArea={heightArea}
                            onClick={() => {
                                setSelected(null);
                            }}
                        />
                        <Layer>
                            <PlanningTasks
                                x={boundsPlanning.x}
                                y={boundsPlanning.y}
                                width={boundsPlanning.width}
                                height={boundsPlanning.height}
                                datesActif={datesActif}
                                areasOffset={areasOffset}
                                widthDate={widthDate}
                                heightArea={heightArea}
                                selected={selected}
                                setSelected={setSelected}
                                withLinksTaskArea={
                                    viewFilters.withLinksTaskArea
                                }
                                links={dataPlanning ? dataPlanning.links : null}
                            />
                            {dataPlanning &&
                                viewFilters.workforceChart ===
                                    Enum_typeChart.withWorkforceCurve && (
                                    <PlanningWorkforceCurve
                                        x={workforceCurve.x}
                                        y={workforceCurve.y}
                                        width={workforceCurve.width}
                                        height={workforceCurve.height}
                                        dates={datesMonthsDays}
                                        widthDate={widthDate}
                                        workforce={dataPlanning.workforce}
                                        workforceSubTrades={
                                            dataPlanning.workforceSubTrades
                                        }
                                        subTrades={dataPlanning.subTrades}
                                        workforceCompanies={
                                            dataPlanning.workforceCompanies
                                        }
                                        companies={dataPlanning.companies}
                                        onClose={(): void => {
                                            setViewFilters(
                                                (
                                                    prev: Type_state_PlanningViewFilters,
                                                ): Type_state_PlanningViewFilters => ({
                                                    ...prev,
                                                    workforceChart:
                                                        Enum_typeChart.none,
                                                }),
                                            );
                                        }}
                                        onResize={resizeWorkforceCurve}
                                    />
                                )}
                            {dataPlanning &&
                                viewFilters.workforceChart ===
                                    Enum_typeChart.withWorkforceSubTradesTab && (
                                    <PlanningWorkforceSubTradesTab
                                        x={workforceSubTradeTab.x}
                                        y={workforceSubTradeTab.y}
                                        width={workforceSubTradeTab.width}
                                        height={workforceSubTradeTab.height}
                                        widthLegend={
                                            workforceSubTradeTab.widthLegend
                                        }
                                        dates={datesMonthsDays}
                                        widthDate={widthDate}
                                        workforce={dataPlanning.workforce}
                                        workforceSubTrades={
                                            dataPlanning.workforceSubTrades
                                        }
                                        subTrades={dataPlanning.subTrades}
                                        onClose={(): void => {
                                            setViewFilters(
                                                (
                                                    prev: Type_state_PlanningViewFilters,
                                                ): Type_state_PlanningViewFilters => ({
                                                    ...prev,
                                                    workforceChart:
                                                        Enum_typeChart.none,
                                                }),
                                            );
                                        }}
                                        onResize={resizeWorkforceSubTradeTab}
                                    />
                                )}
                            {dataPlanning &&
                                viewFilters.workforceChart ===
                                    Enum_typeChart.withWorkforceCompaniesTab && (
                                    <PlanningWorkforceCompaniesTab
                                        x={workforceCompaniesTab.x}
                                        y={workforceCompaniesTab.y}
                                        width={workforceCompaniesTab.width}
                                        height={workforceCompaniesTab.height}
                                        widthLegend={
                                            workforceCompaniesTab.widthLegend
                                        }
                                        dates={datesMonthsDays}
                                        widthDate={widthDate}
                                        workforce={dataPlanning.workforce}
                                        workforceCompanies={
                                            dataPlanning.workforceCompanies
                                        }
                                        companies={dataPlanning.companies}
                                        onClose={(): void => {
                                            setViewFilters(
                                                (
                                                    prev: Type_state_PlanningViewFilters,
                                                ): Type_state_PlanningViewFilters => ({
                                                    ...prev,
                                                    workforceChart:
                                                        Enum_typeChart.none,
                                                }),
                                            );
                                        }}
                                        onResize={resizeWorkforceCompaniesTab}
                                    />
                                )}
                            {dataPlanning && (
                                <PlanningAreasTitle
                                    x={areas.x}
                                    y={scrollY.y - heightPlanningTitle}
                                    width={areas.width - 1}
                                    height={heightPlanningTitle}
                                />
                            )}
                            {dataPlanning && (
                                <PlanningAreas
                                    areasOffset={areasOffset}
                                    x={areas.x}
                                    y={areas.y}
                                    width={areas.width}
                                    height={areas.height}
                                    heightArea={heightArea}
                                    onResize={resizeAreas}
                                    onChangeCloseArea={onChangeCloseArea}
                                />
                            )}
                            {dataPlanning && (
                                <PlanningColsData
                                    areasOffset={areasOffset}
                                    colsData={dataPlanning.colsData}
                                    cols={colsDataSize}
                                    x={colsData.x}
                                    y={colsData.y}
                                    yScroll={scrollX.y}
                                    width={colsData.width}
                                    height={colsData.height}
                                    heightArea={heightArea}
                                    changeColsDataSize={setColsDataSize}
                                    onResize={resizeColsData}
                                />
                            )}
                            {dataPlanning && (
                                <PlanningDates
                                    dates={datesMonthsDays}
                                    x={boundsPlanning.x}
                                    y={1}
                                    width={boundsPlanning.width}
                                    widthDate={widthDate}
                                />
                            )}
                            <Group name="top" />
                        </Layer>
                        <Layer>
                            <PlanningScrolls
                                scrollX={{
                                    ...scrollX,
                                    changePos: changeScrollX,
                                }}
                                scrollY={{
                                    ...scrollY,
                                    changePos: changeScrollY,
                                }}
                                zoomX={{ ...zoomX, changeZoom: changeZoomX }}
                                zoomY={{ ...zoomY, changeZoom: changeZoomY }}
                            />
                        </Layer>
                    </PlanningStage>
                    <PlanningDialogsRightClick />
                </Styled_Planning>
            )}
        </>
    );
};
