import { FormControl, Stack } from "@mui/material";
import {
    DateRangePickerProps,
    DateRangePicker as MuiDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { forwardRef } from "react";

import {
    leftArrowIcon,
    rightArrowIcon,
    switchViewIcon,
} from "src/components/Components_Common/_MuiComponentsVariants/DatePicker/DatePicker.style";
import {
    TMC_FormHelperText,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants/index";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

export type Type_Props_TMC_DateRangePicker = DateRangePickerProps<any> &
    Type_ReadonlyComponent & {
        name?: string;
        startLabel?: string;
        endLabel?: string;
        helperText?: string;
        error?: boolean;
    };

export const DateRangePicker = forwardRef(function DateRangePicker({
    name,
    startLabel,
    endLabel,
    helperText,
    error,
    readonly,
    ...restProps
}: Type_Props_TMC_DateRangePicker) {
    return (
        <Stack
            flexDirection="column"
            data-testid={`DateRangePicker-${name}-container`}
        >
            <FormControl>
                <MuiDateRangePicker
                    calendars={2}
                    slots={{
                        leftArrowIcon: leftArrowIcon,
                        rightArrowIcon: rightArrowIcon,
                        switchViewIcon: switchViewIcon,
                        textField: TMC_TextField,
                    }}
                    localeText={{ start: startLabel, end: endLabel }}
                    disabled={readonly || restProps.disabled}
                    sx={{
                        "&.MuiMultiInputDateRangeField-root": {
                            alignItems: "end",
                            "& .MuiMultiInputDateRangeField-separator": {
                                paddingBottom: "9px",
                            },
                        },
                    }}
                    {...restProps}
                />
                {helperText && error && (
                    <TMC_FormHelperText
                        helperText={helperText as string}
                        inputName={name as string}
                    />
                )}
            </FormControl>
        </Stack>
    );
});
